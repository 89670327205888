import './footer.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
  return (
    <footer className="custom-footer">
      <Container>
        <Row>
          <Col md={4}>
            <h4>Kentucky Coyote Control</h4>
            <br/>
            <p className="custom-footer-text">Predator Problems?  We've got solutions.</p>
          </Col>
          <Col md={4}>
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li><a href="/home">Home</a></li>
              <li><a href="/faq">FAQ</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h4>Reach us</h4>
            <p>ryan@kentuckycoyotecontrol.com</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};