import Card from 'react-bootstrap/Card';
import { faqData } from './Constants';
import "./faq.css";

const Faq = () => {
  return (
    <div className="container mt-5">
      <h1>Frequently Asked Questions</h1>
      {faqData.map((faq, index) => (
        <Card key={index} className="mb-3">
          <Card.Header className={"faq-card-hader"}>{faq.question}</Card.Header>
          <Card.Body className={"faq-card-body"}>
            <Card.Text>
              {faq.answer}
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default Faq;