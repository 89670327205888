export const COYOTE_WARNING_GENERAL = (
  <p>
    Coyotes have become extremely prevalent over the past few decades here in Kentucky. A quick google search on coyotes in Kentucky will show some sobering results. For example,{' '}
    <a href="https://www.wlky.com/article/coyote-attacks-dog-oldham-county-what-to-do/44954136">
      Goshen Ky domestic dog attacked by coyote
    </a>{' '}
    while their owner was just feet away. Or{' '}
    <a href="https://www.wlky.com/article/coyotoes-video-crestwood-kentucky/44581125#">
      Louisville coyotes lurking on porches
    </a>
    . This image was snipped from Reddit where a coyote managed to get over one Kentuckian's six-foot private fence and became trapped in their backyard.
    <br />
  </p>
);

export const COYOTE_WARNING_DISEASE = (
  <p>
    Coyotes can be carriers of diseases such as rabies, distemper, and canine hepatitis, posing health risks to domestic animals and potentially to humans.

    Additionally, they can become a threat to livestock, particularly to smaller animals like chickens, lambs, and calves. For all you hunters out there - coyotes are predators of every species of game. For these reasons, predator management is often necessary to protect both human interests and the broader ecosystem.
  </p>
);

export const YOUR_PROPERTY_STATEMENT = (
  <p>
    Need to get rid of predators? We'll help you out. Prefer handling things on your own? No problem. We're more than happy to point you to good resources and assist any way we can.  
  </p>
);

export const WHY_FREE = (
  <p>
    Our enthusiasm for hunting and trapping is complemented by a genuine eagerness to help locals in need.  We aim to help cull populations that are growing worringly out of control and we need likeminded individuals to work together with us to meet this mutually exclusive goal.  
  </p>
);

export const ANIMAL_LIST = (
  <>
    <p>We can assist you with these species and potentially more:</p>
  <ul>
    <li>Raccoon</li>
    <li>Groundhog</li>
    <li>Opossum</li>
    <li>Beaver</li>
    <li>Otter</li>
    <li>Skunk</li>
    <li>Bobcats</li>
    <li>Bigfoot</li>
  </ul>
  </>
);