export const faqData = [
    {
      question: "Does terminating coyotes actually help control the population?",
      answer: (
        <>
        Unlike many other species, coyotes have few natural predators, and their unchecked numbers can lead to ecological imbalances by outcompeting other species for food. Increasing encounters with humans and pets also pose safety risks and potential disease transmission, such as rabies. For ranchers and farmers, coyote predation represents a significant economic burden that effective management can alleviate. Contrary to the belief that reduced populations will lead to larger litters and quick repopulation, modern, data-driven hunting programs aim for sustainable population levels.  Please see <a href="https://www.dnr.sc.gov/wildlife/coyote/coyoteincentive.html">South Carolinas Coyote Incentive</a> which allegedly reduced coyote populations by 40%.
        </>
      ) 
    },
    {
      question: "What about other nuissance animals besides coyote?",
      answer: "We are happy to help you with any species."
    },
    {
      question: "What do I do if I see a coyote?",
      answer: "Coyotes are usually the most skiddish animals on the planet, however sick k9s or those accustomed to humans may not be so intimidated.  If you encounter a coyote it's important to look big and scary.  Raise your hands and yell at the animal but maintain your distance.  Although coyote attacks are rare on humans outside of urban environments, it's generally considered wise to not your turn your back on a predatory animal. Do not ever feed coyotes, no matter how nicely they ask."
    },
    {
      question: "What is Kentucky Coyote Control?",
      answer: "Kentucky Coyote Control is a small collective of hunters and landowners who wish to reduce the number of predators in the state of Kentucky."
    },
  ];
  