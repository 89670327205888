import 'bootstrap/dist/css/bootstrap.min.css';
import Router from '../Routing/Routing';

function App() {
  return (
        <Router/>
  );
}

export default App;
