import React from 'react';
import './home.css';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner';
import { COYOTE_WARNING_GENERAL, COYOTE_WARNING_DISEASE, YOUR_PROPERTY_STATEMENT, WHY_FREE, ANIMAL_LIST } from './Constants';
import WideWhiteCard from '../../components/WideWhiteCard/WideWhiteCard';

export default function HomePage() {
  const ROW_CLASS_NAME="my-5";
  return (
    <>
      <Container fluid className="homePage">
        <Row className={ROW_CLASS_NAME}>
          <Image src="https://kentucky-coyote-control-images.s3.amazonaws.com/kcc-banner-medium.png"/>
        </Row>

        <Row className={ROW_CLASS_NAME}>
          <WideWhiteCard text={"Our mission is to put you back in control of your property"}/>
        </Row>

        <Row className={ROW_CLASS_NAME}>
          <SimpleBanner 
            backgroundColor="#c5beb2"
            imageSrc="https://kentucky-coyote-control-images.s3.amazonaws.com/coyote-privacy-fence.jpg"
            title={"Kentucky Coyotes"}
            imageMaxWidth="25rem"
            text={ COYOTE_WARNING_GENERAL }
            fadeDirection="ri"
            className=".max-height-row"
           />
        </Row>

        <Row className={ROW_CLASS_NAME}>
          <SimpleBanner 
            backgroundColor="#c5beb2"
            imageSrc="https://kentucky-coyote-control-images.s3.amazonaws.com/coyote-mange-2.jpg"
            title={"Disease"}
            imageMaxWidth="10rem"
            text={ COYOTE_WARNING_DISEASE }
            fadeDirection="left"
            className=".max-height-row"
           />
        </Row>

        <Row className={ROW_CLASS_NAME}>
          <SimpleBanner 
            backgroundColor="#c5beb2"
            imageSrc="https://kentucky-coyote-control-images.s3.amazonaws.com/coyote-4k-at-farm.png"
            title={"Your Property"}
            imageMaxWidth="10rem"
            text={ YOUR_PROPERTY_STATEMENT }
            fadeDirection="ri"
            className=".max-height-row"
           />
        </Row>

        <Row className={ROW_CLASS_NAME}>
          <SimpleBanner 
            backgroundColor="#c5beb2"
            imageSrc="https://kentucky-coyote-control-images.s3.amazonaws.com/game-cam-coyote-night-1.JPG"
            title={"Why is it free?"}
            imageMaxWidth="10rem"
            text={ WHY_FREE }
            fadeDirection="left"
            className=".max-height-row"
           />
        </Row>

        <Row className={ROW_CLASS_NAME}>
          <SimpleBanner 
            backgroundColor="#c5beb2"
            imageSrc="https://kentucky-coyote-control-images.s3.amazonaws.com/mean-coon.png"
            title={"Species"}
            imageMaxWidth="10rem"
            text={ ANIMAL_LIST }
            fadeDirection="ri"
            className=".max-height-row"
           />
        </Row>        
      </Container>
    </>
  );
}