import { Route, Routes } from "react-router-dom";
import Faq from "../../page/Faq/Faq";
import Landing from "../../page/Landing/Landing";
import HomePage from "../../page/Home/Home";
import Contact from "../../page/Contact/Contact";

export default function Router() {
    return( 
        <Routes>
             <Route path="/" element= {<Landing/>}>
                <Route path="home" element={<HomePage/>} />  
                <Route path="faq" element={<Faq/>} />  
                <Route path="contact" element={<Contact/>} />  
             </Route>
        </Routes>
    )
}