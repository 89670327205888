import { Container, Navbar, Nav, Row, Col, Image } from 'react-bootstrap';
import './navigation.css';
import { useEffect, useState } from 'react';

function Navigation() {
  const smallScreenPixels = 500;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < smallScreenPixels);
  const [showNavbar, setShowNavbar] = useState(true); // State to manage navbar visibility

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < smallScreenPixels);
    };

    const handleScroll = () => {
        setShowNavbar(window.scrollY < 50);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll); // Add scroll event listener

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll); // Remove scroll event listener
    }
  }, [isSmallScreen]); // Include isSmallScreen in the dependency array

  const navBarBrandText = () => {
    if (isSmallScreen) {
      return (
        <Container className="navbar-brand-text pe-0">
          Kentucky <br/>Coyote <br/>Control <br/>
        </Container>
      )
    } else {
      return (
        <Container className="navbar-brand-text pe-0">
          Kentucky Coyote Control <br/>
        </Container>
      );
    }
  };

  // Apply a class conditionally to hide the navbar
  const navbarClasses = `custom-navbar ${showNavbar ? '' : 'hide-navbar'}`; // Adjust the condition here
  const freeRemovalClass = `freeRemoval ${showNavbar ? '' : 'hide-free-removal'}`; // Adjust the condition here

  return (
    <div className="fixedWrapper">
      <Navbar expand="xl" className={navbarClasses}> {/* Use the updated class name here */}
        <Container className="alignNavLeft">
          <Navbar.Brand href="home">
            <Row className="align-items-center">
              <Col xs="auto" className="pe-0">
                <Image
                  src="https://kentucky-coyote-control-images.s3.amazonaws.com/coyote-full-body.png"
                  height="80"
                />
              </Col>
              <Col className="pe-0">
                {navBarBrandText()}
              </Col>
            </Row>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="home">Home</Nav.Link>
              <Nav.Link href="faq" className="faq-link">FAQ</Nav.Link>
              <Nav.Link href="contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className={freeRemovalClass}>
        <a href="contact">Free Predator Removal</a>
      </Container>
    </div>
  );
}

export default Navigation;
