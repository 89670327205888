import Navigation from '../../components/Navigation/Navigation';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import './landing.css';

export default function Landing() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate('/Home');
    }
  }, [navigate, location.pathname]);

  return (
    <>
      <Navigation />
      <div className="mainContent">
          <Outlet />
      </div>
      <Footer />
    </>
  )
}
