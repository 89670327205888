import React from 'react';
import { useState, useEffect } from 'react';

export default function SimpleBanner({ backgroundColor, imageSrc, text, fadeDirection, title, maxHeight, defineSmallScreenPixels, defineMediumScreenPixels }) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const smallScreenPixels = defineSmallScreenPixels ?? 768;
  const mediumScreenPixels = defineMediumScreenPixels ?? 1024;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < smallScreenPixels);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [smallScreenPixels]);

  const isFadeLeft = fadeDirection === 'left';

  const calculateImageSize = () => {
    if (window.innerWidth < smallScreenPixels) {
      return { width: '100%', height: 'auto' };
    } else if (window.innerWidth >= smallScreenPixels && window.innerWidth < mediumScreenPixels) {
      return { width: '90%', height: 'auto' };
    } else {
      return { width: '70%', height: 'auto' };
    }
  };

  const containerStyle = {
    display: 'flex',
    alignItems: isSmallScreen ? 'flex-start' : 'center',
    justifyContent: isSmallScreen ? 'center' : 'space-between',
    flexDirection: isSmallScreen ? 'column' : isFadeLeft ? 'row' : 'row-reverse',
    minWidth: '80%',
    maxWidth: '95%',
    marginLeft: isFadeLeft ? 'auto' : '0',
    marginRight: isFadeLeft ? '0' : 'auto',
    backgroundColor: backgroundColor,
    color: 'black',
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: isFadeLeft ? '20px 0 0 20px' : '0 20px 20px 0'
  };

  const textAreaStyle = {
    zIndex: 1,
    flex: 1,
    maxWidth: isSmallScreen ? '100%' : '50%',
    padding: isSmallScreen ? '0' : '0 20px',
    boxSizing: 'border-box',
  };

  const fadeStyle = {
    position: 'absolute',
    left: isFadeLeft ? 'auto' : 0,
    right: isFadeLeft ? 0 : 'auto',
    top: 0,
    bottom: 0,
    width: '100px',
    background: isFadeLeft 
      ? 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
      : 'linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
  };

  const imageStyle = {
    ...calculateImageSize(),
    flexShrink: 0,
    objectFit: 'contain',
  };

  const imageContainerStyle = {
    flex: 1,
  };

  const textStyle = {
    fontSize: "clamp(16px, 2vw, 24px)", // Adjusts between 16px and 24px based on the viewport width
    paddingLeft: isSmallScreen ? "5%" : "0",
    paddingRight: isSmallScreen ? "5%" : "0"
  }
  
  const headerStyle = {
    fontSize: "clamp(20px, 2.5vw, 32px)", // Adjusts between 20px and 32px based on the viewport width
    marginTop: isSmallScreen ? "10px" : "0px"
  }

  return (
    <div style={containerStyle}>
      {isSmallScreen && <div style={imageContainerStyle}>
        <img src={imageSrc} alt="" style={imageStyle} />
      </div>}
      <div style={fadeStyle}></div>
      <div style={textAreaStyle}>
        <h2 style={headerStyle}>{title}</h2>
        <p style={textStyle}>{text}</p>
      </div>
      {!isSmallScreen && <div style={imageContainerStyle}>
        <img src={imageSrc} alt="" style={imageStyle} />
      </div>}
    </div>
  );
}
