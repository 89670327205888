import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Alert, Row, Col, Image } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaPhone, FaPaperPlane } from 'react-icons/fa';
import "./contact.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    contactMethod: '',
    message: '',
  });
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    if(formData.contactMethod != '' && formData.message != '' && formData.name !='') {
      setIsSubmitButtonEnabled(true);
    }
    else {
      setIsSubmitButtonEnabled(false);
    }
  }, [formData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {

      try {
        const response = await fetch('/api/contactUs', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
          }),
        });

        if (response.ok) {
          setShowThankYou(true);
          setTimeout(() => setShowThankYou(false), 5000); 
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
        } else {
          alert('Submission failed, please try sending us an email directly: ryan@kentuckycoyotecontrol.com');
        }
      } catch (error) {
        alert('An error occurred, please try sending us an email directly: ryan@kentuckycoyotecontrol.com');
      }
    }
  };

  return (
    <Container className="mt-5 contactContainer">
      <Row className="my-2">
        <h2>Contact Us</h2>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group controlId="formGroupName">
              <Form.Label><FaUser /> Name</Form.Label>
              <Form.Control required type="text" placeholder="John Smith" name="name" value={formData.name} onChange={handleChange} minLength={2} maxLength={25}/>
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formGroupEmail">
              <Form.Label><FaEnvelope /> <FaPhone/> Email address (or Phone #)</Form.Label>
              <Form.Control required placeholder="johnSmith@kentucky.net" name="contactMethod" value={formData.contactMethod} onChange={handleChange} />
              <Form.Control.Feedback type="invalid">
                Please provide your email address (or phone number)
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formGroupMessage" >
          <Form.Label><FaPaperPlane /> Message</Form.Label>
          <Form.Control as="textarea" rows={3} required placeholder="Coyotes are attacking cats in my neighborhood, help!" name="message" value={formData.message} onChange={handleChange} minLength={15} maxLength={1024} />
          <Form.Control.Feedback type="invalid">
            Please enter a message.
          </Form.Control.Feedback>
        </Form.Group>

        {showThankYou && <Alert variant="success" className="animate__animated animate__fadeIn">Thanks! We'll contact you soon.</Alert>}

        <Button className="my-2" variant="primary" type="submit" disabled={!isSubmitButtonEnabled}>
          Submit
        </Button>

      </Form>
    </Container>
  );
};

export default ContactForm;
